import { useContext } from "react";
import { HeaderContext } from "./Header";
const Hamburger = ({ className='', onClick }) => {
    const {open} = useContext(HeaderContext);
  return ( 
    <button className={'hamburger '+className + (open?" open":'')} onClick={onClick}>
        <div className="ham-menu">
            <span className="line" style={{transform: open?'rotate(45deg)':'rotate(0)'}}></span>
            <span className="line" style={{opacity: open?"0":"1"}}></span>
            <span className="line" style={{transform: open?'rotate(-45deg)':'rotate(0)'}}></span>
        </div>
    </button>
  )
};

export default Hamburger;