import axios from "axios";
import Layout from "./Layout"
import pic from "./assets/img/illu/partneripic.png";
import { APIURL, STORAGEURL } from ".";
import { useEffect, useState } from "react";
import FsLightbox from "fslightbox-react";
import ReactPlayer from 'react-player';
import Loading from "./components/UI/Loading";


const Media = () => {  
    const [data, setData] = useState(null);
    const [srcs, setSrcs] = useState(null);
    const [slide, setSlide] = useState(null);
    const [lightbox, setLightbox] = useState(false);

    const fetchData = async () => {
        await axios
            .post(APIURL + "/collection/items", {
                type: 'galerie',
                orderAsc: "ASC",
                limit: 100,
                page: 1, 
            })
            .then((response) => { 
                setSrcs(response.data.body.items.map(i=>(STORAGEURL+i.photo)));
                let arr = [];
                for (let i = 0; i < response.data.body.items.length; i += 3) {
                    const chunk = response.data.body.items.slice(i, i + 3);
                    arr.push(chunk);
                }
                setData(arr);   
            });
    }

    useEffect(() => {
        fetchData()  
    }, [])
    useEffect(() => {
        console.log(lightbox)  
        if (lightbox) {

        }
    }, [lightbox])

    return (
        <Layout nested={1} className="base kontakt">
            <img className="picmedia" src={pic} alt="illustration" />
            <div className="base__header smaller">
                <div className="base__header__right">
                    <h2>Press</h2>
                </div>
            </div>
            <h3>PR a komunikace s médii</h3>
            <div className="kontakty">
                <div className="kontakty__kontakt">
                    <strong>Naďa Šeráková</strong>
                    <a href='mailto:festival@kreativnipraha.eu' className="inline">nada@serakovan.cz</a>
                </div>
                <div className="kontakty__kontakt">
                    <span>Vaše dotazy či výzvy směřujte na email a my Vám rádi odpovíme: <a href='mailto:festival@kreativnipraha.eu' className="inline">festival@kreativnipraha.eu</a></span>
                </div>
            </div> 
            <h3>Ke stažení</h3>
            <div className="kontakty">
                <div className="kontakty__kontakt">
                    <a href="Poznej Vltavu_loga.zip" target='_blank'>Loga</a>
                    <a href="Poznej Vltavu_vizual.pdf" target='_blank'>Vizuál</a>
                </div>
            </div>
            <h3>Média</h3>
            <div className="kontakt__gallery">
                {data?.map((row, i) =>(
                    <div key={i} className={"kontakt__gallery__row "+((i+1)%2===0?'even':(i+1)%3===0?'odd oddRight':'odd oddLeft')}>
                        {row.map(file=>(
                            <div key={file.id} className="galleryItem" onClick={() => {setSlide(file.photo);setLightbox(!lightbox)}}>
                                {(/\.(gif|jpe?g|tiff?|png|webp|bmp)$/i).test(file.photo)?<img src={STORAGEURL+file.photo} alt={file.name}/>: <div className="video"><ReactPlayer url={STORAGEURL+file.photo} /></div>}
                            </div>
                        ))}
                    </div>
                ))||<Loading />}
            </div>
            {srcs&&<FsLightbox
				toggler={lightbox}
				sources={srcs}
                source={STORAGEURL+slide}
			/>}
        </Layout>
    )
}

export default Media