import Header from "./components/Header/Header";
import Navigation from "./components/Header/Navigation";
import logoBile from "./assets/svg/logo_bile.svg";
import { showCookieConsent } from "./components/UI/Cookies";
import Overlay from "./components/Header/Overlay";
import { Link } from "react-router-dom";
import HashLink from "./components/UI/HashLink";

import map from "./assets/svg/map.svg";
import mail from "./assets/svg/mail.svg";
import FB from "./assets/svg/FB.svg";
import IG from "./assets/svg/IG.svg";

import Praha from "./assets/img/akteri/PRAHA.svg";
import KREP from "./assets/img/akteri/KREP.svg";
import ipr from "./assets/img/akteri/IPR.svg";
import { useEffect } from "react";

const Layout = ({ children, nested = 0, ...rest }) => {
    return (
        <>
            <Header stopScroll breakpoint={1000}>
                <div className="container">
                    <Link to="/">
                        <img
                            src={logoBile}
                            className="pv0"
                            alt="PV0 + KOOP logo"
                        />{" "}
                    </Link>

                    <Navigation moveRight hideOnMobile={1000}>
                        <HashLink href="#intro" nested={nested}>
                            O festivalu
                        </HashLink>
                        <HashLink href="#mapa" nested={nested}>
                            Mapa
                        </HashLink>
                        <HashLink href="#partneri" nested={nested}>
                            Aktéři
                        </HashLink>
                        <HashLink
                            id="terminyBtn"
                            className="button orange"
                            href="/udalosti"
                            nested={nested}
                        >
                            Události
                        </HashLink>
                    </Navigation>
                </div>
                <Overlay width="50vw" justify="space-evenly">
                    <HashLink href="#intro" nested={nested}>
                        O festivalu
                    </HashLink>
                    <HashLink href="#mapa" nested={nested}>
                        Mapa
                    </HashLink>
                    <HashLink href="#partneri" nested={nested}>
                        Aktéři
                    </HashLink>
                    <HashLink
                        id="terminyBtn"
                        className="button orange"
                        href="/udalosti"
                        nested={nested}
                    >
                        Události
                    </HashLink>
                </Overlay>
            </Header>
            <main {...rest}>{children}</main>
            <footer>
                <div className="footer_partners">
                    <a href="https://praha.eu/" target="_blank">
                        <img src={Praha} alt="Praha" />
                    </a>
                    <a href="https://iprpraha.cz/" target="_blank">
                        <img src={ipr} alt="ipr" />
                    </a>
                    <a href="https://www.kreativnipraha.eu/cs" target="_blank">
                        <img src={KREP} alt="KREP" />
                    </a>
                </div>
                <div className="footer">
                    <div className="footer__left">
                        <div className="container">
                            <img src={logoBile} alt="PV0 + KOOP logo" />
                        </div>
                        <div className="soc">
                            {/*<img src={IG} alt="Instagram" />*/}
                            <a href=" https://www.facebook.com/events/3623831417762671?ref=newsfeed" ><img src={FB} alt="Facebook" /></a>
                        </div>
                    </div>
                    <div className="footer__center" style={{ display: "none" }}>
                        <h5>Kontatní informace</h5>
                        <div>
                            <img src={map} />
                            <p>
                                Kreativní Praha, z. ú.
                                <br />U Radnice 10
                                <br />
                                110 00 Praha 1
                            </p>
                        </div>
                        <div>
                            <img src={mail} />
                            <p className="mail">
                                Naděžda Šeráková
                                <br />
                                nada@serakovan.cz
                            </p>
                        </div>
                    </div>
                    <div className="footer__right">
                        <div>
                            <HashLink nested={nested} href="#intro">
                                O festivalu
                            </HashLink>
                            <HashLink nested={nested} href="/udalosti">
                                události
                            </HashLink>
                            {/*<a href="/mapa.pdf" target="_blank">
                                Mapa
                            </a>*/}
                            <HashLink nested={nested} href="/akteri">
                                Aktéři
                            </HashLink>
                            <HashLink nested={nested} href="/zastity">
                                Záštity
                            </HashLink>
                        </div>
                        <div>
                            <HashLink nested={nested} href="/kontakty">
                                KONTAKTY
                            </HashLink>
                            <HashLink nested={nested} href="/media">
                                PRESS 
                            </HashLink> 
                            <HashLink nested={nested} href="/gdpr">
                                GDPR 
                            </HashLink>
                            <a onClick={() => showCookieConsent()}>Cookies</a>
                        </div>
                    </div>
                </div>
                <div className="copyright">
                    Copyright © {new Date().getFullYear()} Kreativní Praha z. ú.
                </div>
            </footer>
        </>
    );
};

export default Layout;
