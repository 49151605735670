import { Link } from "react-router-dom"; 

import hybnost from "../assets/svg/hybnost.svg";

const Event = ({ event }) => {
    return (
        <div className="event">
            <div className="event__header"> 
                <Link to={"/udalosti/" + event.id}>{event.baner?<img src={event.baner} alt={"Obrázek události"} />:<img src="/demo.png" />}</Link>
                <div className="event__header__intro"> 
                    <p>
                        {event?.time_from.slice(0, 5)}
                        {event?.time_to && "-" + event.time_to.slice(0, 5)}
                    </p>
                    {event.handicap =="true" && <img src={hybnost} alt="Facebook" /> } 
                </div>
            </div>
            <div className="event__body">
            <Link to={"/udalosti/" + event.id}><h2>{event.name}</h2></Link>
                {event.url_map ? (
                    <Link to={event.url_map}>{event.place}</Link>
                ) : (
                    <span>{event.place}</span>
                )}
            </div>
            <div className="event__footer">
                <span>{event.price ? event.price + " Kč" : "zdarma"}</span>
                <Link to={"/udalosti/" + event.id} className="button orange">
                    Detail
                </Link>
            </div>
        </div>
    );
};

export default Event;
