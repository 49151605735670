import { useLenis } from "@studio-freight/react-lenis";
import { useContext, useRef } from "react";
import {HeaderContext} from '../Header/Header'
import { Link } from "react-router-dom";

const HashLink = ({children, href, nested, ...rest}) => {
    const lenis = useLenis();
    const header = useContext(HeaderContext);

    const handleClick = (e) => {
        if ((e.target.href.split('/#')[0]+'/')==window.location.href.split('/#')[0]) {
            e.preventDefault();
            if (header?.open) {header.setOpen(false)}
            setTimeout(() => {
                lenis.scrollTo(document.getElementById(href.split('#')[1]));
            }, 50);
        }
    }

    return <Link onClick={handleClick} to={'../'.repeat(nested)+href} {...rest}>{children}</Link>
}

export default HashLink