import React from 'react';
import ReactDOM from 'react-dom/client';
import './scss/main.scss';
import App from './App';
import { BrowserRouter } from 'react-router-dom';

const host = window.location.host;
const http = window.location.protocol;
let a = host;
if (host.includes('localhost')) a = '127.0.0.1:8000';
if (host.includes('devel')) a = 'poznejvltavu.tw-planner.com';
export const STORAGEURL = host.includes('localhost') ? "http://127.0.0.1:8000" : "";
if (host.includes('poznejvltavu')) a = window.location.host + "/index.php";
export const APIURL = http + "//" + a + "/api";
export const formatDate = (date, time) => {
  let datum = date.replaceAll('-', '/')
  let d = new Date(datum)

  if (time) {
    return <>
      <a style={{marginRight:".5rem"}}>{d.getDate()+". "+(d.getMonth()+1)+". "+d.getFullYear()} </a> {time.slice(0,5)}</>
  } else return d.getDay() + ". " + (d.getMonth()+1) + ". " + d.getFullYear()
}

const root = ReactDOM.createRoot(document.body);
root.render(
  // <React.StrictMode>
  <BrowserRouter basename='/'>
    <App />
  </BrowserRouter>
  // </React.StrictMode>
);
