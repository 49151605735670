const Navigation = ({ children, className, hideOnMobile, ...rest }) => {
  let show = hideOnMobile&&(window.innerWidth<=hideOnMobile) ? false : true;
  if (show) return <div className={'nav '+className}
    style={{
      background: rest.bg||'transparent',
      borderRadius: rest.borderRadius||'0',
      padding: rest.padding||'10px',
      gap: rest.gap||'10px',
      marginLeft: rest.moveRight?'auto':'0',
      display: "flex"
    }}
  >{children}</div>
};
export default Navigation;